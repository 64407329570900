import React from 'react';
/* eslint-disable sort-keys */
import PhoneLogo from '../images/contact-us/callLogo.png';
import LocationLogo from '../images/contact-us/locationLogo.png';
import MailLogo from '../images/contact-us/mailLogo.png';

export const ContactUsData = {
  title: 'Contact US',
  contactInfo: {
    title1: 'Get In Touch',
    phone: '(703)560-2200 Main',
    mail: 'hr@forellagroup.com',
    mail2: 'marketing@forellagroup.com',
    title2: 'Our Locations',
    phoneLogo: PhoneLogo,
    mailLogo: MailLogo,
    locationLogo: LocationLogo,
    locations: [
      {
        state: 'Virginia',
        address: (
          <>
            5180 Parkstone Drive, Suite 250,
            <br />
            Chantilly, VA 20151-3812
            <br />
            (703)277-3473 Fax
          </>
        ),
      },
      {
        state: 'Maryland',
        address: (
          <>
            34 Atherton Rd,
            <br />
            Lutherville, MD 21093-5704
            <br />
            (410)941-9020
          </>
        ),
      },
    ],
  },
};
