import React from 'react';
import { Router } from '@reach/router';
import NotFoundPage from './404';
import ContactUs from '../components/ContactUs';
import { ContactUsData } from '../data/contactUsData';

const ConatctUsRouter = (props) => (
  <Router>
    <ContactUs
      path="/contact-us"
      {...props}
      detailInfo={ContactUsData}
      page="contact-us"
    />
    <NotFoundPage default />
  </Router>
);

export default ConatctUsRouter;
