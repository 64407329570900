import PropTypes from 'prop-types';
import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

const ContactusContainer = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allContactUsContentJson {
          edges {
            node {
              bannerImage
              bottomBannerTitle
              id
              intro
              title
            }
          }
        }
      }
    `}
    render={({ allContactUsContentJson: { edges: contactusData } }) =>
      children(
        contactusData.map((e) => ({
          ...e.node,
        }))
      )
    }
  />
);

ContactusContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default ContactusContainer;
