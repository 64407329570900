import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../base/Box';
import SEO from '../SEO';
import Container from '../Container';
import ContactForm from '../ContactForm';
import ContentArea from '../ContentArea';
import PageBanner from '../PageBanner';
import Title from '../Title';
import CallUs from '../CallUsButton';
import ContactusContainer from '../../containers/ContactusContainer';

const ContactUs = ({ detailInfo, page }) => {
  const { locationLogo } = detailInfo.contactInfo;
  return (
    <ContactusContainer>
      {(contactUsData) => {
        const pageName = page || 'contact-us';
        const detailInfoJson = contactUsData
          .map((ele) => {
            return ele.id === pageName ? ele : null;
          })
          .find((el) => el);
        return (
          <Box>
            <SEO />
            {/* <SEO description="" title="Contact Us" /> */}
            <PageBanner image={detailInfoJson.bannerImage} />

            <SEO description="" title="Contact Us" />
            <Container
              sx={{
                mb: 10,
                width: ['100%', null, null, null, 'maxWidths.content'],
              }}
            >
              <Title
                sx={{
                  color: 'grays.4',
                  fontFamily: 'tertiary',
                  fontSize: 6,
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  lineHeight: '28px',
                  mb: '30px',
                  mt: [3, null, null, '57px', null],
                }}
              >
                {detailInfoJson.title}
              </Title>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: ['column-reverse', null, null, 'row', null],
                }}
              >
                <ContentArea
                  sx={{
                    pt: [4, null, null, 'unset', null],
                    width: ['100%', null, null, '50%', null],
                  }}
                >
                  {detailInfoJson.intro && (
                    <Box
                      sx={{
                        color: 'grays.4',
                        fontFamily: 'tertiary',
                        fontSize: 3,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        mb: '17px',
                      }}
                    >
                      {detailInfoJson.intro}
                    </Box>
                  )}
                  <ContactForm isContactPage />
                </ContentArea>
                <ContentArea
                  sx={{
                    maxWidth: [null, null, null, '383px', null],
                    mb: [null, null, null, '-24px', null],
                    ml: [null, null, null, 'auto', null],
                    width: [null, null, null, '50%', null],
                  }}
                >
                  <Box
                    sx={{
                      ml: [null, null, null, '-30px', null],
                    }}
                  >
                    <Box
                      sx={{
                        color: 'grays.10',
                        fontFamily: 'tertiary',
                        fontSize: 3,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                      }}
                    >
                      <Title
                        sx={{
                          color: 'grays.10',
                          fontFamily: 'tertiary',
                          fontSize: 5,
                          fontStyle: 'normal',
                          fontWeight: 'bold',
                          lineHeight: '23px',
                          mb: '14px',
                          paddingLeft: 5,
                          textTransform: 'unset',
                        }}
                      >
                        {detailInfo.contactInfo.title1}
                      </Title>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'row',
                          lineHeight: '30px',
                        }}
                      >
                        <img
                          alt="Phone: "
                          height="17px"
                          src={detailInfo.contactInfo.phoneLogo}
                          width="19px"
                        />
                        <Box
                          as="a"
                          href="tel:+(703)560-2200"
                          sx={{
                            '&:hover': { color: 'warning' },
                            color: 'grays.10',
                            paddingLeft: 3,
                          }}
                        >
                          {detailInfo.contactInfo.phone}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'row',
                          lineHeight: '30px',
                        }}
                      >
                        <img
                          alt="Mail: "
                          height="14px"
                          src={detailInfo.contactInfo.mailLogo}
                          width="20px"
                        />
                        <Box
                          as="a"
                          href={`mailto:${detailInfo.contactInfo.mail}`}
                          sx={{
                            '&:hover': { color: 'warning' },
                            color: 'grays.10',
                            paddingLeft: 3,
                          }}
                        >
                          {detailInfo.contactInfo.mail}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'row',
                          lineHeight: '30px',
                        }}
                      >
                        <img
                          alt="Mail: "
                          height="14px"
                          src={detailInfo.contactInfo.mailLogo}
                          width="20px"
                        />
                        <Box
                          as="a"
                          href={`mailto:${detailInfo.contactInfo.mail2}`}
                          sx={{
                            '&:hover': { color: 'warning' },
                            color: 'grays.10',
                            paddingLeft: 3,
                          }}
                        >
                          {detailInfo.contactInfo.mail2}
                        </Box>
                      </Box>
                    </Box>
                    <Title
                      sx={{
                        color: 'grays.10',
                        fontFamily: 'tertiary',
                        fontSize: 5,
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        lineHeight: '23px',
                        mb: '27px',
                        mt: '39px',
                        paddingLeft: 5,
                        textTransform: 'unset',
                      }}
                    >
                      {detailInfo.contactInfo.title2}
                    </Title>

                    {detailInfo.contactInfo.locations.map((location, index) => (
                      <Box
                        key={index}
                        sx={{
                          mb: '23px',
                        }}
                      >
                        {location.state && (
                          <Box
                            sx={{
                              alignItems: 'center',
                              display: 'flex',
                              flexDirection: 'row',
                              mb: '3px',
                            }}
                          >
                            <img
                              alt="Address: "
                              height="19px"
                              src={locationLogo}
                              width="12px"
                            />
                            <Box
                              sx={{
                                color: 'warning',
                                fontFamily: 'tertiary',
                                fontSize: 5,
                                fontStyle: 'normal',
                                fontWeight: 'bold',
                                lineHeight: '25px',
                                mb: '3px',
                                paddingLeft: 4,
                              }}
                            >
                              {location.state}
                            </Box>
                          </Box>
                        )}
                        {location.address && (
                          <Box
                            sx={{
                              color: 'grays.10',
                              fontFamily: 'tertiary',
                              fontSize: 3,
                              fontStyle: 'normal',
                              fontWeight: 'normal',
                              lineHeight: '27px',
                              paddingLeft: 5,
                            }}
                          >
                            {location.address}
                          </Box>
                        )}
                      </Box>
                    ))}
                  </Box>
                </ContentArea>
              </Box>
            </Container>
            <Box
              sx={{
                bg: 'grays.0',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                minHeight: [null, null, null, '254px', null],
                px: [2, null, null, 'unset', null],
                py: 7,
              }}
            >
              {detailInfoJson.bottomBannerTitle && (
                <Title
                  sx={{
                    color: 'grays.3',
                    fontFamily: 'tertiary',
                    fontSize: 9,
                    fontStyle: 'normal',
                    fontWeight: 'black',
                    lineHeight: [null, null, null, null, '20px'],
                    mb: '19px',
                    textAlign: 'center',
                  }}
                >
                  {detailInfoJson.bottomBannerTitle}
                </Title>
              )}
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <CallUs />
              </Box>
            </Box>
          </Box>
        );
      }}
    </ContactusContainer>
  );
};

ContactUs.propTypes = {
  detailInfo: PropTypes.shape().isRequired,
  page: PropTypes.string,
};

ContactUs.defaultProps = {
  page: '',
};

export default ContactUs;
